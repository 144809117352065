import React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import MaintenanceStyles from './maintenance.module.css'

type DataProps = {
  site: {
    buildTime: string
  }
}

const MaintenanceMode: React.FC<PageProps<DataProps>> = () => (
  <Layout>
    <SEO title="cerveza.guide" />
    <div className={MaintenanceStyles.container}>
      <a className={MaintenanceStyles.attribution} href='https://www.freepik.com/vectors/background'>Background vector created by kotkoa - www.freepik.com</a>
    </div>
  </Layout>
)

export default MaintenanceMode

export const query = graphql`
  {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }
  }
`
import React from "react"

import Layout from "../components/layout"
import Maintenance from "../components/maintenance/maintenance"
import Image from "../components/image"
import SEO from "../components/seo"

// TODO: Should come from envvars
const isMaintenanceMode = true;

const App = () => (
  <Layout>
    <SEO title="Home" />
    <h1>Hi people</h1>
    <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>
  </Layout>
)

const Page = isMaintenanceMode ? Maintenance : App

const IndexPage = () => <Page />

export default IndexPage
